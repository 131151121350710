<script setup lang="ts">
const dialog = useState('showCookieModal')

const acceptGDPR = () => {
  dialog.value = false
  const cookie = useCookie('gdprAccepted')
  cookie.value = true
}
</script>

<template>
  <v-dialog
    :v-model="dialog"
    class="cookie"
    style="max-width: 1300px"
    height="100%"
    location="bottom"
    transition="dialog-bottom-transition"
    persistent
    close-delay="2000"
  >
    <v-card
      style="
        position: absolute;
        bottom: -15px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
      "
      class="px-4 pt-4 pt-md-6 px-md-6"
    >
      <v-row class="text-body-1">
        <v-col cols="12" md="7">
          <v-card-title>
            <h3 class="text-h5 text-md-h3">Datenschutzeinstellungen</h3>
          </v-card-title>
          <v-card-text>
            Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere
            uns helfen, diese Website und Ihre Erfahrung zu verbessern.
            <p></p>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="2" class="pl-8 pl-md-0 pt-0 d-flex flex-column justify-center">
          <!-- vuetify checkbox -->
          <v-checkbox density="compact" hide-details :model-value="true" disabled label="Checkbox">
            <template #label>
              <p class="pl-4" style="font-size: 0.6em">Notwendige Cookies</p>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="3" class="pa-5 d-flex flex-column">
          <v-btn
            block
            aria-label="Alle Cookies akzeptieren"
            color="primary"
            flat
            @click="acceptGDPR"
          >
            Alle Akzeptieren
          </v-btn>
          <v-btn
            block
            class="mt-4"
            aria-label="Ausgewählte Cookies akzeptieren"
            color="black"
            variant="outlined"
            @click="acceptGDPR"
          >
            Auswahl bestätigen
          </v-btn>
        </v-col>
      </v-row>
      <hr class="mt-5" style="width: 98%; margin: auto" />
      <!-- button for impressum and Datenschutz -->
      <div color="black" class="d-flex justify-space-between justify-md-end text-caption py-2">
        <NuxtLink aria-label="Impressum" class="link pr-md-4" to="/impressum"> Impressum </NuxtLink>
        <NuxtLink aria-label="Datenschutz" class="link" to="/datenschutz"> Datenschutz </NuxtLink>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.link {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
    text-decoration-color: rgb(var(--v-theme-primary));
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }
}
</style>
