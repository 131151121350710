<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type MenuItem from '~~/types/menu/MenuItem'

const { mobile } = useDisplay()

const drawer = ref(false)
const menuItems = ref<MenuItem[]>([
  {
    title: 'START',
    route: '/'
  },
  {
    title: 'WIR',
    route: '/wir/gerd-kaimer',
    class: 'wir',
    children: [
      { title: 'STIFTUNG', route: '/wir/stiftung' },
      { title: 'GREMIEN', route: '/wir/gremien' },
      { title: 'GERD KAIMER', route: '/wir/gerd-kaimer' }
    ]
  },
  {
    title: 'HELFEN',
    children: [{ title: 'Spenden und Stiften', route: '/helfen/spendenundstiften' }]
  },
  {
    title: 'AKTUELLES',
    children: [
      { title: 'FONDS HEIMAT 2025', route: '/aktuelles/fonds-heimat-2025' },
      { title: '650 Jahre Solingen', route: '/aktuelles/650jahre' },
      { title: 'TISCHLEIN-DECK-DICH', route: '/aktuelles/tischlein' },
      { title: 'Projekte', route: '/aktuelles/projekte' }
    ]
  },
  {
    title: 'FÖRDERUNG',
    class: 'foerderung',
    children: [
      { title: 'SATZUNG', route: '/satzung' },
      { title: 'VERGABERICHTLINIEN', route: '/vergaberichtlinien' }
    ]
  },
  {
    title: 'KONTAKT',
    route: '/kontakt'
  }
])

defineComponent({
  name: 'HeaderComponent'
})
</script>

<template>
  <div>
    <ClientOnly>
      <!-- Mobile Menu -->
      <v-navigation-drawer v-model="drawer" width="350" location="end" temporary>
        <v-row style="height: 70px" justify="end" align="center">
          <Icon class="mr-5" size="2em" name="system-uicons:cross" @click="drawer = !drawer"></Icon>
        </v-row>
        <v-list aria-label="Navigation">
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            role="option"
            aria-selected="false"
          >
            <h4
              :aria-label="`Seite ${item.title} oeffnen`"
              class="text-body-1 font-weight-bold"
              style="font-family: 'DinAlternate', sans-serif"
              @click="item.route && navigateTo(item.route)"
            >
              <strong>{{ item.title }}</strong>
            </h4>

            <v-list-item
              v-for="(child, childIndex) in item.children"
              :key="childIndex"
              :to="child.route"
            >
              <h4
                :aria-label="`Unterseite ${child.title} oeffnen`"
                :class="item.class"
                class="text-body-1 font-weight-bold"
                style="font-family: 'DinAlternate', sans-serif"
              >
                <Icon class="mr-1" name="ic:twotone-arrow-right" color="primary" />{{ child.title }}
              </h4>
            </v-list-item>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <!-- Mobile Header -->
      <v-app-bar
        v-if="mobile"
        app
        :elevation="0"
        style="position: relative; overflow: visible"
        height="100"
      >
        <NuxtLink to="/">
          <NuxtImg
            src="/images/logo.png"
            height="70px"
            width="127px"
            loading="lazy"
            sizes="140px sm:160px md:180px lg:200px xl:220px"
            format="webp"
            alt="Gerd Kaimer Logo"
            class="ml-5"
            preload
          />
        </NuxtLink>
        <v-spacer></v-spacer>
        <v-btn aria-label="Menue Oeffnen" x-large icon @click="drawer = !drawer">
          <Icon size="1.3em" name="fluent:navigation-20-regular" />
        </v-btn>
      </v-app-bar>

      <!-- Desktop Header -->
      <v-app-bar
        v-else
        fixed
        app
        :elevation="0"
        style="position: relative; overflow: visible"
        height="100"
        flat
        class="big-screen-nav"
      >
        <NuxtLink to="/">
          <NuxtImg
            src="/images/logo.png"
            height="70px"
            width="127px"
            loading="lazy"
            sizes="80px sm:100px md:120px lg:140px xl:160px"
            format="webp"
            alt="Gerd Kaimer Logo"
            class="ml-5"
            preload
          />
        </NuxtLink>
        <v-spacer></v-spacer>
        <template v-for="(item, index) in menuItems" :key="index">
          <div class="px-0">
            <v-menu
              v-if="item.children && item.children?.length > 0"
              style="font-family: 'DinAlternate', sans-serif"
              open-on-hover
            >
              <template #activator="{ props }">
                <v-btn
                  :to="item.route"
                  class="px-6 mx-2"
                  :aria-label="`Seite ${item.title} oeffnen`"
                  v-bind="props"
                >
                  <h3 class="text-body-1 font-weight-bold text-gray">
                    {{ item.title }}
                  </h3>
                </v-btn>
              </template>
              <v-card width="300px">
                <v-list>
                  <v-list-item
                    v-for="(child, childIndex) in item.children"
                    :key="childIndex"
                    :value="index"
                    :to="child.route"
                  >
                    <h4
                      :aria-label="`Unterseite ${child.title} oeffnen`"
                      class="text-uppercase text-body-1"
                      :class="item.class"
                      style="line-height: 1em"
                    >
                      <Icon class="mr-1" name="ic:twotone-arrow-right" color="primary" />
                      {{ child.title }}
                    </h4>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <v-btn
              v-else
              :to="item.route"
              class="px-6 mx-2"
              :aria-label="`Unterseite ${item.title} oeffnen`"
            >
              <h3 class="text-body-1 font-weight-bold text-gray">
                {{ item.title }}
              </h3>
            </v-btn>
          </div>
        </template>
      </v-app-bar>
    </ClientOnly>
  </div>
</template>

<style scoped lang="scss">
.text-gray {
  color: #646d74;
}
</style>
